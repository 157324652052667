* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0px;
  padding: 0;
  background-color: #000000;
}

@media (min-width: 768px) {
  .logo {
    margin-top: 64px;
    margin-left: 72px;
    width: 210px;
    position: absolute;
    float: left;
    cursor: pointer;
  }

  .bgContainer {
    display: flex;
    position: absolute;
    float: left;
    width: 100%;
    height: 100%;
  }

  .shortStatus {
    float: right;
    position: absolute;
    margin-top: 20%;
    right:20%;
  }

  .coffeeTitle {
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: medium;
    font-size: 71px;
    color: #00DBDB;
  }
  
  .coffeeStatusShort {
    text-align: center;
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: bold;
    font-size: 144px;
    color: #00DBDB;
  }

  .coffeeStatusDetailed {
    font-size: 42px;
    position: absolute;
    right: 72px;
    bottom: 64px;
  }

  .statusLabel {
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    color: #AAABAB;
  }

  .bgImage {
    float: left;
    width: 100%;
    object-fit: cover;
    object-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("assets/bg.jpg");
  }
}

@media (max-width: 768px) {
  .logo {
    margin-top: 16px;
    margin-left: 16px;
    width: 128px;
    position: absolute;
    float: left;
    cursor: pointer;
  }

  .bgContainer {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .shortStatus {
    float: left;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0%;
  }

  .coffeeTitle {
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: medium;
    font-size: 36px;
    color: #00DBDB;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40%;
    width: fit-content;
  }
  
  .coffeeStatusShort {
    text-align: center;
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    font-weight: bold;
    font-size: 128px;
    margin-top: 30%;
    color: #FFF;
  }

  .coffeeStatusDetailed {
    font-size: 24px;
    position: absolute;
    bottom: 64px;
    text-align: center;
    width: 100%;
  }

  .statusLabel {
    font-family: "Helvetica Neue", "Helvetica", sans-serif;
    color: #AAABAB;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .bgImage {
    float: none;
    width: 100%;
    object-fit: cover;
    object-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("assets/bg_mobile.jpg");
    background-position: center;
    background-position-y: 0px;
  }
}


.bgGradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  width: 100%;
  height: 200px;;
  bottom: 0px;
  position: inherit;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}
